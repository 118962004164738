<template>
  <div eagle-form-item="data-list">
    <v-list
      v-if="listDataArray"
      :dense="dense"
    >
      <template v-for="(listDataItem, listDataItemIndex) in listDataArray">
        <dataListItem
          :key="listDataItemIndex"
          :index="listDataItemIndex"
          :item="listDataItem"
          :dense="dense"
          :form-key="formKey"
        >
          <template
            v-if="listDataItem.dataSlot"
            v-slot:data="{data}"
          >
            <component
              :data="data"
              :formKey="formKey"
              :formData="formData"
              :is="listDataItem.dataSlot"
            ></component>
          </template>
        </dataListItem>
      </template>
    </v-list>
  </div>
</template>

<script lang="babel" type="text/babel">
import formItemMixin from '@/components/form/mixins/formItemMixin'
export default {
  mixins: [formItemMixin],
  computed: {
    dense() {
      return this.formDataItem.dense === true
    },
    listDataArray() {
      const dataArray = this.formDataItem.dataArray(this.storeData, this.formInfo)
      if(!Array.isArray(dataArray)) return null
      if(dataArray.length == 0) return null
      return dataArray
    },
  },
  components: {
    dataListItem: () => import('./dataList/dataListItem.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
